import { Carousel } from "flowbite-react";

export default function Project({images, desc}) {

    return (
        <div>
            <div className="h-screen px-4 py-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <Carousel
                    slideInterval={10000} 
                    indicators={false}
                    leftControl={
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-gray-600 stroke-1 w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                    }
                    rightControl={
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-gray-600 stroke-1 w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                    }>
                    {images.map(image => (
                        <img
                        src={image.src}
                        alt={image.alt}
                        className="max-h-[90vh] max-w-max"
                        />
                    ))}
                </Carousel>
            </div>
            <div className="px-4 py-4 sm:px-6 lg:max-w-7xl lg:px-16">
                <h2 class="text-2xl font-extrabold dark:text-white">{desc.title}</h2>
                <p class="mb-3 text-gray-500 dark:text-gray-400">{desc.paragraph}</p>
            </div>
        </div>
    );
}