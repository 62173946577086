
import GridGallery from "../components/GridGallery";
import FoggyForest from "../images/FoggyForest.webp"
import Molo from "../images/Molo.webp"
import Arch1 from "../images/230503_Camera_Architettura_DSCF3522_HDR.webp"
import Arch2 from "../images/230503_Camera_Architettura_DSCF3532_HDR.webp"
import Arch3 from "../images/230503_Camera_Architettura_DSCF3541_HDR.webp"
import Arch4 from "../images/230503_Camera_Architettura_DSCF3537_HDR.webp"
import Arch5 from "../images/230503_Camera_Architettura_DSCF3544_HDR 1.webp"
import Mountains from "../images/IMG_0332.webp"
import Soffione from "../images/Soffione.webp"
import WhoAmI from "../images/230528_Camera_FinalProject_DSCF3640.webp"
import ScanMe from "../images/230528_Camera_FinalProject_DSCF3646.webp"
import RunAway from "../images/230528_Camera_FinalProject_DSCF3668.webp"

const images = [
    {
      name: 'Kyoto temple',
      src: "https://source.unsplash.com/BcvH1raPORA",
      alt: '...',
    },
    {
      name: 'Nara',
      src: "https://source.unsplash.com/SHsk8LwsbNo",
      alt: '...',
    },
    {
      name: 'Kyoto',
      src: "https://source.unsplash.com/GQcvjaQx5SE",
      alt: '...',
    },
    {
      name: 'Osaka Street',
      src: "https://source.unsplash.com/sJ7gOr0loA0",
      alt: '...',
    },
    {
      name: 'Hakone',
      src: "https://source.unsplash.com/XZaYSQ6wZ_Y",
      alt: '...',
    },
    {
      name: 'Osaka Castle',
      src: "https://source.unsplash.com/7EE9AI18uGg",
      alt: '...',
    },
    {
      name: 'Who Am I?',
      src: WhoAmI,
      alt: '...',
    },
    {
      name: 'Scan me',
      src: ScanMe,
      alt: '...',
    },
    {
      name: 'Run Away',
      src: RunAway,
      alt: '...',
    },
    {
      name: 'Campus Einaudi p4',
      src: Arch4,
      alt: 'Black and white image of a zoomed detail of a building.',
    },
    {
      name: 'Campus Einaudi p2',
      src: Arch2,
      alt: 'Black and white image of the top of a building.',
    },
    {
      name: 'Campus Einaudi p3',
      src: Arch3,
      alt: 'Black and white image of a building from a lower position poit of view.',
    },
    {
      name: 'Campus Einaudi p1',
      src: Arch1,
      alt: 'Black and white image of a metal structure.',
    },
    {
      name: 'Campus Einaudi p5',
      src: Arch5,
      alt: 'Black and white image of a metal structure.',
    },
    {
      name: 'Foggy Forest',
      src: FoggyForest,
      alt: 'Black and white image of a foggy forest.',
    },
    {
      name: 'Molo',
      src: Molo,
      alt: 'Black and white image of a boat.',
    },
    {
      name: 'Mountains',
      src: Mountains,
      alt: 'Black and white image of mountains.',
    },
    {
      name: 'Soffione',
      src: Soffione,
      alt: 'Macro photography of a dandelion',
    }
  ]

  export default function Portraits() {
    return (
      <GridGallery images={images} />
    )
  }
