import {Helmet} from "react-helmet";
import { Outlet, Link } from "react-router-dom";
import { Footer } from "flowbite-react";
import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Bars3Icon } from '@heroicons/react/20/solid'
import ReactGA from 'react-ga4';
import { AiFillInstagram, AiFillLinkedin } from "react-icons/ai";

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

  const menuList = [
    { name: 'Home', to: '/' },
    /*{ name: 'Portrait', to: '/portrait' },*/
    {
        id: 'project',
        name: 'Projects', 
        to: '/',
        options: [
            { value: 'p1', label: 'Under Pressure', to: 'underpressure'},
            /*{ value: 'p2', label: 'Project 2', to: 'project2'},
            { value: 'p3', label: 'Project 3', to: 'project3'},*/
        ]
    },
    { name: 'About', to: '/about' },
  ]

export default function Root() {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

  return (
    <div className="bg-white font-chivomono">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Portfolio - Emanuele Bono</title>
          <meta name="description" content="The portfolio of Italian Photographer, Emanuele Bono – a photographer based out of Turin, Italy." />
          <link rel="canonical" href="http://photography.emanuelebono.com/" />
      </Helmet>
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-end px-4">
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Nav Menu */}
                  <form className="mt-4">
                    <h3 className="sr-only">Categories</h3>
                    <ul role="list" className="px-2 py-3 font-medium text-gray-900">
                      {menuList.map((category) => category.options? (
                        <Disclosure as="div" key={category.id} className="px-2 py-3">
                        {({ open }) => (
                          <>
                            <h3 className="-mx-2 -my-3 flow-root">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                <span className="font-medium text-gray-900">{category.name}</span>
                              </Disclosure.Button>
                            </h3>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                            >
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-6">
                                {category.options.map((option, optionIdx) => (
                                  <div key={option.value} className="flex items-center">
                                    <label
                                      htmlFor={`filter-mobile-${category.id}-${optionIdx}`}
                                      className="ml-3 min-w-0 flex-1 text-gray-500"
                                    >
                                      <Link to={option.to} onClick={() => setMobileFiltersOpen(false)}>
                                        {option.label}
                                        </Link>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                      ) : (
                        <li key={category.name}>
                            <Link className="block px-2 py-3" to={category.to} onClick={() => setMobileFiltersOpen(false)}>{category.name}</Link>
                        </li>
                      ))}
                    </ul>
                    <ul>
                    <div className="flex justify-start space-x-2 text-xl px-4">
                      <a href="https://www.instagram.com/mrwolfjunior" target="_blank" rel="noreferrer">
                        <AiFillInstagram />
                      </a>
                      <a href="https://www.linkedin.com/in/emanuelebono/" target="_blank" rel="noreferrer">
                        <AiFillLinkedin />
                      </a>
                    </div>
                    </ul>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <main className="mx-auto px-4 sm:px-6 lg:px-6">
          <div className="flex items-center justify-between border-b border-gray-200 py-4 lg:hidden">
            <Link to="/">
                <span className="text-4xl font-bold tracking-tight text-gray-900">Emanuele Bono</span>
            </Link>
            <div className="flex items-center">
              <button
                type="button"
                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="sr-only">Menu</span>
                <Bars3Icon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>

          <section aria-labelledby="main-menu">

            <div className="h-screen flex">
              {/* Nav Menu */}
              <form className="hidden lg:block w-40 sm:w-64">
                
                <div className="sm:max-w-lg py-16">
                    <Link to={"/"}>
                        <h1 className="font text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Emanuele
                        </h1>
                        <h1 className="font text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Bono
                        </h1>
                    </Link>
                </div>
                
                <h3 className="sr-only">Categories</h3>
                <ul role="list" className="space-y-4 pb-6 text-sm font-medium text-gray-900">
                  {menuList.map((category) => category.options? (
                    <Disclosure as="div" key={category.id}>
                    {({ open }) => (
                      <>
                        <h3 className="-my-3 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">{category.name}</span>
                          </Disclosure.Button>
                        </h3>
                        <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-4">
                            {category.options.map((option, optionIdx) => (
                              <div key={option.value} className="flex items-center">
                                <label
                                  htmlFor={`filter-${category.id}-${optionIdx}`}
                                  className="ml-3 text-sm text-gray-600"
                                >
                                    <Link to={option.to}>
                                    {option.label}
                                    </Link>
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                  ) : (
                    <li key={category.name}>
                        <Link to={category.to}>{category.name}</Link>
                    </li>
                  ))}
                </ul>
                <ul>
                  <div className="flex justify-start space-x-2 text-xl	">
                    <a href="https://www.instagram.com/mrwolfjunior" target="_blank" rel="noreferrer">
                      <AiFillInstagram />
                    </a>
                    <a href="https://www.linkedin.com/in/emanuelebono/" target="_blank" rel="noreferrer">
                      <AiFillLinkedin />
                    </a>
                  </div>
                </ul>
              </form>

              {/* Product grid */}
              <div className="flex-1 flex overflow-hidden">
                <div className="flex-1 overflow-y-scroll">
                {/* Your content */}
                <Outlet />
                <Footer container={true}>
                  <div className="w-full text-center">
                    <Footer.Divider />
                    <Footer.Copyright
                      by="Emanuele Bono"
                      href="https://www.emanuelebono.com/"
                      year={new Date().getFullYear()}
                    />
                  </div>
                </Footer>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
  }