import {Helmet} from "react-helmet";
import Project from "../components/Project";
import Obligacion from "../images/230430_CameraProject_Obligation_DSCF3508.webp"
import Mute from "../images/230513_Camera_FinalProject_DSCF3574 1 1 1.webp"
import BornToBeFree from "../images/230528_Camera_FinalProject_DSCF3635.webp"
import WhoAmI from "../images/230528_Camera_FinalProject_DSCF3640.webp"
import ScanMe from "../images/230528_Camera_FinalProject_DSCF3646.webp"
import RunAway from "../images/230528_Camera_FinalProject_DSCF3668.webp"
import ImARobot from "../images/230528_Camera_FinalProject_DSCF3693.webp"

const images = [
    {
      src: WhoAmI,
      alt: '...',
    },
    {
      src: Mute,
      alt: '...',
    },
    {
      src: ImARobot,
      alt: '...',
    },
    {
      src: ScanMe,
      alt: '...',
    },
    {
      src: RunAway,
      alt: '...',
    },
    {
      src: BornToBeFree,
      alt: '...',
    },
    {
      src: Obligacion,
      alt: '...',
    },
  ];

const desc = {
    title: "Under Pressure",
    paragraph: "The photographs that I have captured depict the toll that work is taking on individuals. Through these images, I aim to shed light on the stress and pressure that we are constantly subjected to in our daily lives. The long hours, tight deadlines, and demanding bosses can all contribute to a sense of overwhelm that can be hard to shake off. My hope is that these photographs will serve as a reminder to take a step back, breathe, and prioritize our mental health and well-being."
}


export default function Project1() {

    return (
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Under Pressure - Emanuele Bono</title>
            <meta name="description" content="Capturing the Impact of Stress on the Human Experience Through Photography" />
            <link rel="canonical" href="http://photography.emanuelebono.com/underpressure" />
          </Helmet>
          <Project images={images} desc={desc} />
        </div>
    );
}