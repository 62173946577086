import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

export default function GridGallery({images}) {
    return (
        <PhotoProvider className="backgroudRemoval">
            <div className="bg-white">
                <div className="mx-auto max-w-2xl px-4 py-4 sm:px-6 lg:max-w-7xl lg:px-8">
                    <h2 className="sr-only">Products</h2>
                    <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2 lg:grid-cols-3">
                    {images.map((image, index) => (
                        <a key={index} className="group">
                        <div className="aspect-h-5 aspect-w-4 w-full overflow-hidden">
                        <PhotoView src={image.src}>
                            <img src={image.src}
                                alt={image.alt}
                                className="h-full w-full object-cover"
                                loading={ index <= 6? "eager" : "lazy"}
                            />
                        </PhotoView>
                        </div>
                        { /* <h3 className="mt-4 text-sm text-gray-700">{image.name}</h3> */}
                        </a>
                    ))}
                    </div>
                </div>
            </div>
        </PhotoProvider>
    )
}
