import React from 'react';
import Root from "./routes/root";
import Portraits from "./routes/portraits";
import ErrorPage from "./error-page";
import About from "./routes/about";
import Project1 from "./routes/project1"

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Portraits />,
      },
      {
        path: "/portrait",
        element: <Portraits />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/underpressure",
        element: <Project1 />,
      },
    ],
  },
]);

export default function App() {
  return (
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
  );
}